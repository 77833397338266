import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "../components/header"
import {StyledWrapper, StyledFooter, StyledMain} from "./layoutStyle"
import Transition from '../components/transition'
import {GlobalStyles} from '../components/GlobalStyles';

const Index = ({ children, location }) => (
  <StaticQuery
    query={graphql`query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }`}
    render={data => (
      <StyledWrapper>
        <GlobalStyles />
        <Header siteTitle={data.site.siteMetadata.title} />
          <StyledMain>
            <Transition location = {location}>
              {children}
            </Transition>
          </StyledMain>
          <StyledFooter>
            © {new Date().getFullYear()}, created by @sharpshooter90 for passion
          </StyledFooter>
      </StyledWrapper>
    )}
  />
)
Index.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Index
