import styled from "styled-components"

export const StyledWrapper = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0px 1.0875rem 1.45rem;
  padding-top: 0;
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 200px); 
`

export const StyledMain = styled.main`
  flex: 1;
`;

export const StyledFooter = styled.footer`
  color: #999999;
  font-size: 0.7rem;
`;