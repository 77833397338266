import styled from "styled-components";
import {rgba} from 'polished'

export const InnerHeader = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0.72rem 1.0875rem;
  display: flex;
  align-items: center;
  background: ${rgba('#ffffff', 0.9)};
`;

export const headerStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
};

export const Brand = styled.div`
  flex-grow: 1;
`;

export const HeaderRightContent = styled.div`
  
`;

export const navLinkStyle = {
  padding: '16px 24px',
  textDecoration: 'none',
  color: '#999',
};

export const activeStyles = {
  position: 'relative',

  '&:after': {
    content: '',
    position: 'absolute',
    width: 'calc(100% - 48px)',
    margin: '0 auto',
    bottom: 0,
    left: 0,
    height: '3px',
    background: '#101010',
  }
};

export const StyledNotification = styled.div`
  background: #101010;
  color: #fff;
  padding: 8px 16px;
  text-align: center;
  font-size: 14px;
`

export const StyledLink = styled.a`
  text-decoration: none;
`