import styled, {css} from "styled-components"

const Button = styled.div`
  color: #101010;
  border-bottom: #101010;
  position:relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 16px;
 
  
  ${props => props.secondary && css`
    padding: 2px 8px;
    background: #fff;
    
    &:after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: currentColor;
    }
  `}
  
  ${props => props.ghost && css`
    border: 3px solid #fff;
    
`}
`;


export default Button