import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Grid, Row, Col } from 'react-flexbox-grid'

import Button from "./Button"

import {InnerHeader, Brand, HeaderRightContent, activeStyles, navLinkStyle, headerStyle, StyledNotification, StyledLink} from './headerStyle';

const siteLogo = (
  <svg width="42" height="42" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><defs><circle id="a" cx="20" cy="20" r="20"/></defs><g transform="translate(1 1)" fill="none" fillRule="evenodd"><mask id="b" fill="#fff"><use xlinkHref="#a"/></mask><use stroke="#000" strokeWidth="2" fill="#000" xlinkHref="#a"/><path d="M19.375 11.255l-26.87 26.87 26.87 26.87 26.87-26.87-26.87-26.87z" strokeOpacity=".87" stroke="#FFF" strokeWidth="2" fill="#000" mask="url(#b)"/><path d="M10 11.255l-26.87 26.87L10 64.995l26.87-26.87L10 11.255z" strokeOpacity=".87" stroke="#FFF" strokeWidth="2" fill="#000" mask="url(#b)"/><circle stroke="#000" fill="#FFF" cx="29" cy="20" r="6"/></g></svg>
)

const Header = ({ siteTitle }) => (
  <header style={headerStyle}>
    {/*<StyledNotification>*/}
    {/*  Hi there, I'm working on my case studies at the moment. I will publishing by end of Aug for any more queries please contact me at&nbsp;<StyledLink href="mailto:sudeep.mp.in@gmail.com?cc=sudipmelepunathilmp@gmail.com&subject=Hello Sudeep! (via adventureland.io)">*/}
    {/*  <Button secondary>sudeep.mp.in@gmail.com</Button></StyledLink>*/}
    {/*</StyledNotification>*/}
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <InnerHeader>
              <Brand>
                <Link to="/">
                  {siteLogo}
                </Link>
              </Brand>
              <HeaderRightContent>
                <Link to="/case-studies" style={navLinkStyle} activeStyle={activeStyles}>Case Studies</Link>
                <a href="https://drive.google.com/file/d/1lj5hVYSzgGsBv-3Pzt1mDJiOJZjZ82Qn/view?usp=sharing" style={navLinkStyle} target="_blank">Resume</a>
                <Link to="/info" style={navLinkStyle} activeStyle={activeStyles}>Info</Link>
              </HeaderRightContent>
            </InnerHeader>
          </Col>
        </Row>
      </Grid>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
